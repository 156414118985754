export const IconConfig = {
  selected: 'fa-sharp  fa-solid',
  default: 'fa-sharp fa-light ',
  highlighted: 'fa-sharp fa-solid',
};

export const common_icons = {
  snapshot: 'fa-stream',
  auditLogs: 'fa-clipboard-question',
  debug: 'fa-bug',
  details: 'fa-list',
  pdf: 'fa-file-pdf',
  csv: 'fa-file-csv',
  excel: 'fa-file-excel',
};

export enum ModuleIcons {
  home = 'fa-home-lg',
  assets = 'fa-microchip',
  inspector = 'fa-magnifying-glass',
  utilisation = 'fa-clock',
  servicing = 'fa-wrench',
  search = 'fa-search-location',
  dashboards = 'fa-chart-tree-map',
  operations = 'fa-folder-gear',
  workOrders = 'fa-wrench',
  transport = 'fa-truck-container',
  people = 'fa-people-group',
  reports = 'fa-file-chart-line',
  dataExplorer = 'fa-magnifying-glass-chart',
  assetGroups = 'fa-object-intersect',
  geofenceManagement = 'fa-object-group',
  workflowManagement = 'fa-diagram-project',
  admin = 'fa-user-tie',
  help = 'fa-question-circle',
  inventory = 'fa-clipboard-list',
  connectors = 'fa-laptop-medical',
  settings = 'fa-gear',
  users = 'fa-user',
  devices = 'fa-tachograph-digital',
}

export const WorkflowIcons = {
  SNAPSHOT: common_icons.snapshot,
  DETAILS: common_icons.details,
  SCOPE: 'fa-object-union',
  CONDITION: 'fa-diagram-project',
  SCHEDULE: 'fa-calendar',
  ACTIONTRIGGER: 'fa-lightbulb-exclamation',
  ACTIONRESOLVE: 'fa-lightbulb-exclamation',
  HISTORY: 'fa-history',
  AUDITLOGS: common_icons.auditLogs,
  DEBUG: common_icons.debug,
} as const;

export const WorkflowTypeIcons = {
  CONTINUOUS: 'fa-rotate-right',
  PERIODIC: 'fa-alarm-clock',
  EVENTS: 'fa-bell',
  SCHEDULED: 'fa-calendar-days',
} as const;
